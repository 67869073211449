<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
                <b-row>
                    <b-col>
                        <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                            {{ $t('elearning_tim.trainee_evaluation') + ' ' + $t('globalTrans.details') }}
                        </list-report-head>
                    </b-col>
                </b-row>
              <b-row>
                <b-col lg="12" sm="12">
                  <b-table-simple class="tg mt-3" hover small caption-top>
                    <tbody>
                      <tr>
                        <th style="width: 15%"  class="text-right" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
                        <th class="text-center" style="width: 5%">:</th>
                        <td style="width: 30%" class="text-left">{{ formData.circular_memo_no }}</td>
                        <th style="width: 15%"  class="text-right" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">{{ $t('elearning_config.organization') }}</th>
                        <th style="width: 5%" class="text-center" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">:</th>
                        <td style="width: 30%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">{{ $i18n.locale === 'bn' ? formData.org_bn : formData.org }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%" class="text-right" >{{ $t('elearning_config.fiscal_year') }}</th>
                        <th class="text-center" style="width: 5%">:</th>
                        <td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('elearning_config.office_type') }}</th>
                        <th class="text-center" style="width: 5%">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.office_type_bn : formData.office_type }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('globalTrans.office') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.office_bn : formData.office }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_type') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_category') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_category_bn : formData.training_category }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_title') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</td>
                      </tr>
                      <tr>
                        <th style="width: 15%"  class="text-right">{{ $t('elearning_tim.feedback') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.feedback_bn : formData.feedback_en }}</td>
                        <th style="width: 15%"  class="text-right">{{ $t('globalTrans.remarks') }}</th>
                        <th style="width: 5%" class="text-center">:</th>
                        <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.remarks_bn : formData.remarks_en }}</td>
                      </tr>
                    </tbody>
                  </b-table-simple>

                  <b-table-simple striped bordered small class="mt-2">
                    <b-thead>
                      <tr>
                        <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                        <b-th class="text-center">{{ $t('elearning_tim.course_name') }}</b-th>
                        <b-th class="text-right">{{ $t('elearning_tim.total_marks') }}</b-th>
                        <b-th class="text-right">{{ $t('elearning_tim.attain_marks') }}</b-th>
                      </tr>
                    </b-thead>
                    <b-tbody>
                      <template v-if="formData.trainee_evaluation_details.length">
                        <b-tr v-for="(item, index) in formData.trainee_evaluation_details" :key="index">
                          <b-td  class="text-center">{{ index+1 }}</b-td>
                          <b-td class="text-center">{{ currentLocale === 'en' ? item.course_name : item.course_name_bn }}</b-td>
                          <b-td class="text-right">{{ item.total_marks }}</b-td>
                          <b-td class="text-right">{{ item.attain_marks }}</b-td>
                        </b-tr>
                      </template>
                      <template v-else>
                        <b-tr>
                          <b-td colspan="4" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                        </b-tr>
                      </template>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'

export default {
    components: {
      ListReportHead
    },
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.formData.trainee_evaluation_details = this.formData.trainee_evaluation_details.map(item => {
        const course = this.$store.state.TrainingElearning.commonObj.courseList.find(courseObj => courseObj.value === item.course_id)
        const customItem = {
          course_name: course?.text_en,
          course_name_bn: course?.text_bn
        }
        return Object.assign({}, item, customItem)
      })
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      officeTypeList: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tim.trainee_evaluation') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.formData, this)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
